import Home from './components/Home';
import Projects from './components/Projects';
import './components/home.css';

function App() {
  return (
    <div className="test">
      <Home />
      <Projects />
    </div>
  )
}

export default App;
